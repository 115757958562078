import React from "react"
import Ample from "../components/ample/ample"
import TickerDropDown from "../components/tickerdropdown"
import TradingViewChart from "../components/tradingview/chart"
import TradingViewSymbolOverview from "../components/tradingview/symboloverview"
import SocialPanel from "../components/stocktwits/socialpanel"
//style={{borderWidth:"1px",borderColor:"#cccccc",borderStyle:"solid"}}

export default class TickerReportPage extends React.Component {
  state = {
    ticker: "AMZN"
  }

  tickerChanged = newTicker => {
    this.setState({ticker:newTicker});
  }

    render() {
        const seoDescription="Visualizations of stock price trends and social media sentiment for NASDAQ and NYSE tickers";
        return (
            <Ample  currPageTitle="Ticker Report" seoDescription={seoDescription}  location={this.props.location.href}>
            <div className="row  " >  
                <div className="col-lg-8 col-sm-12 col-xs-12">
                    <div className="row" >
                        <div className="col-lg-12 col-sm-12 col-xs-12"  >
                            <div className="card" style={{minWidth:"380px"}}>
                            <div className="card-body">
                                <TickerDropDown OptionsOnly={false} defaultTicker={this.state.ticker}  onSelectedTickerChanged={this.tickerChanged} helperText=""/>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  "  >
                        <div className="col-lg-12 col-sm-12 col-xs-12"  >
                        <div className="card" >
                            <TradingViewSymbolOverview ticker={this.state.ticker}/>
                        </div>
                        </div>
                    </div>
                    <div className="row"  >
                        <div className="col-lg-12 col-sm-12 col-xs-12"  >
                        <div className="card" style={{visibility:(this.state.ticker!=="")?'visible':'hidden'}} >
                            <TradingViewChart width="100%" ticker={this.state.ticker}/>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-xs-12">
                    <div style={{minWidth:"380px",maxWidth:"380px",visibility:(this.state.ticker!=="")?'visible':'hidden'}}>
                    <SocialPanel ticker={this.state.ticker} />
                    </div>
                </div>
            </div>
            </Ample>);
    }
}
