import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";


export default class TradingViewSymbolOverview extends React.Component {
  tvparams = {
    "symbol": "NASDAQ:AAPL",
    "width": "100%",
    //"height":"100",
    "locale": "en",
    "colorTheme": "light",
    "isTransparent": true
  }

  removeWidgetScript() {
    const oldWidgetScript = document.getElementById("tradingingsymboloverviewscript")
    if (oldWidgetScript != null) oldWidgetScript.parentElement.removeChild(oldWidgetScript)
  }
  shouldBeVisible() {
    return (this.props.ticker && this.props.ticker !== "");
  }

  // The Symbol Overview widget from TradingView can be refreshed by simply re-injecting the symbol-info.js script with different parameters
  refreshWidget() {
    this.removeWidgetScript()
    if (!this.shouldBeVisible()) return;

    this.injectTradingViewJavascript();
  }

  //if the requested ticker has changed, refresh the chart
  componentDidUpdate(prevProps) {
    if (prevProps.ticker !== this.props.ticker) { //ticker has changed
      this.refreshWidget();
    }
  }
  
  //fetch TradingView's javascript file and inject it into the control
  // the tvparams json must be injected into the script
  //  this must be done to effect a refresh of the widget
  injectTradingViewJavascript() {
    const script = document.createElement('script')
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js"
    script.async = true
    script.id="tradingingsymboloverviewscript"
    this.tvparams.symbol=this.props.ticker.replace("-",".");
    script.innerHTML = JSON.stringify(this.tvparams)
    document.getElementById("tradingingsymboloverviewwidgetcontainer").appendChild(script);
    //script.onload = () => {this.replaceCopyright()} //whenever we refresh the ticker in the widget, we have to update the copyright statement
  }

  componentDidMount(){
    this.refreshWidget();
  }
  /*replaceCopyright() {
    const oldCopyright = document.getElementById("tradingview-widget-copyright")
    if (oldCopyright != null) oldCopyright.parentElement.removeChild(oldCopyright)
      document.getElementById("tradingingsymboloverviewwidgetcontainer").appendChild(this.generateCopyrightBlurb())
  }*/

  copyrightBlurb() {
    const tvURL = "https://www.tradingview.com/symbols/"+this.props.ticker
    let content = <><OutboundLink href={tvURL} rel="noreferrer noopener" target="_blank">
                <span className="blue-text" >{this.props.ticker} Price Today</span></OutboundLink> by TradingView</>

    /*
   const copyright=document.createElement('div')
   copyright.className="tradingview-widget-copyright"
   copyright.id="tradingview-widget-copyright"
   //copyright.style={borderColor:"#cc0000",borderWidth:"1px",borderStyle:"solid"}
   ReactDOM.render(content,copyright)
    */
    return content;
    
  }

  //TradingViewSymbolOverview renders as a div which will be populated by the RefreshChart() method
  //  tradingingsymboloverviewwidgetcontainer is the main container. 
  //  tradingview-widget-container__widget is the div which contains the widget. we render an empty one to start
  //  tradingview-widget-copyright is the div which contains the dynamic copyright. we render an empty one to start
  render() {
    return (
      <div id="tradingingsymboloverviewwidgetcontainer" style={{visibility:(this.shouldBeVisible()===true)?'visible':'hidden'}}  className="tradingview-widget-container">
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright" id="tradingview-widget-copyright"></div>
      </div>
    )
  }

  }



