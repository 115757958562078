import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default class StockTwitsFeed extends React.Component {
    twitparams =   {
        container: 'stocktwits-widget-news',
        symbol: 'AAPL',
        width: '340', height: '600', limit: '25', scrollbars: 'true',
        streaming: 'true',
        title: 'AAPL Ideas', 
        style: {link_color: '4871a8', link_hover_color: '4871a8', header_text_color: '000000', border_color: 'cecece', divider_color: 'cecece', divider_type: 'solid', box_color: 'f5f5f5', stream_color: 'ffffff', text_color: '000000', time_color: '999999'}
    }
    
    removeFeed() {
        const oldFeed = document.getElementById("stocktwits-widget-news")
        oldFeed.innerHTML=""
    }
    //the widget should not be visible if we don't have a ticker
    shouldShowFeed() {
        return (this.props.ticker && this.props.ticker !== "");
    }

    // To cause the chart to re-render, instantiate a StockTwits widget (client-side)
    // and stick it in the stocktwitsfeedcontainer
    refreshFeed() {
        this.removeFeed();

        // if we're hidden, don't bother rendering
        if (this.shouldShowFeed()===false) return;

        //create a javascript snippet which instantiates the StockTwits widget with our current parameters
        const twitsscript = document.createElement('script')
        this.twitparams.symbol=this.props.ticker
        this.twitparams.title=""
        twitsscript.innerHTML = " STWT.Widget("+JSON.stringify(this.twitparams)+");"
        //inject this snippet into the container
        // this will cause stocktwits-widget-news to be populated with a rendering of the widget
        document.getElementById("stocktwitsfeedcontainer").appendChild(twitsscript)
    }
  
    //if the requested ticker has changed, refresh the feed
    componentDidUpdate(prevProps) {
        if (prevProps.ticker !== this.props.ticker) { //ticker has changed
            this.refreshFeed();
        }
    }

    //Once we are sure the component has rendered, inject a javascript controller from StockTwits into the control
    componentDidMount(){
        this.injectStickTwitsJavascript();
    }

    injectStickTwitsJavascript() {
        const script = document.createElement('script')
        script.src = "https://api.stocktwits.com/addon/widget/2/widget-loader.min.js"
        document.getElementById("stocktwitsfeedcontainer").appendChild(script)

        script.onload = () => { //once the stocktwits JS has loaded, instantiate the feed (if we've got a ticker)
            this.refreshFeed();
        }
    }


    //StockTwitsFeed renders as a div which will be populated by the refreshFeed() method
    //  stocktwitsfeedcontainer is the main container. 
    //  stocktwits-widget-news is the div which contains the feed widget. we render an empty one to start
    render() {
        return (
            <div id="stocktwitsfeedcontainer" style={{visibility:(this.shouldShowFeed()===true)?'visible':'hidden'}}>
                <div id="stocktwits-widget-news"></div>
                <OutboundLink href='https://stocktwits.com' style={{fontSize:"0px"}}>Stocktwits</OutboundLink>
            </div>
        )
    }

  }



