import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

/*
  Embed a TradingView price history chart
    Props: - ticker (when "" the chart will be hidden)
           - width (defaults to 100%)
      
*/
export default class TradingViewChart extends React.Component {
  //specify the ticker and other parameters to control the UI of the chart
  tvparams =   {
    //"width": "100%",
    //"height": 610,
    //"symbol": "AAPL",
    "interval": "D",
    "timezone": "Etc/UTC",
    "theme": "light",
    "style": "1",
    "locale": "en",
    "toolbar_bg": "#f1f3f6",
    "hide_side_toolbar": false,
    "enable_publishing": false,
    "allow_symbol_change": false,
    "container_id": "tradingview_274b1"
  }

  //the chart should not be visible if we don't have a ticker
  shouldShowChart() {
    return (this.props.ticker && this.props.ticker !== "");
  }

  /*
    to get the chart to refresh (with a new ticker), we must delete and re-render it
  */
  clearChart() {
    const oldChart = document.getElementById("tradingview_274b1");
    if (oldChart && oldChart!==null) {
      oldChart.innerHTML="";
    }
  }

  // To cause the chart to re-render, instantiate a TradingView.widget (client-side)
  // and stick it in the tradingviewchartcontainer
  refreshChart() {
    this.clearChart();

    // if we're hidden, don't bother rendering
    if (this.shouldShowChart()===false) return;

    //create a javascript snippet which instantiates the TradingView widget with our current parameters
    const widgetscript = document.createElement('script');
    this.tvparams.symbol=this.props.ticker.replace("-",".");
    this.tvparams.width=(this.props.width)?this.props.width:"100%";
    widgetscript.innerHTML = " new TradingView.widget("+JSON.stringify(this.tvparams)+");"
    //inject this snippet into the container
    // this will cause tradingview_274b1 to be populated with a rendering of the widget
    document.getElementById("tradingviewchartcontainer").appendChild(widgetscript)

  }
  
  //if the requested ticker has changed, refresh the chart
  componentDidUpdate(prevProps) {
    if (prevProps.ticker !== this.props.ticker) { //ticker has changed
      this.refreshChart();
    }
  }

  //fetch TradingView's javascript file and inject it into the control
  injectTradingViewJavascript() {
    const script = document.createElement('script')
    script.src = "https://s3.tradingview.com/tv.js"
    document.getElementById("tradingviewchartcontainer").appendChild(script)

    script.onload = () => { //once the tradingview JS has loaded, render the chart (if we've got a ticker)
        this.refreshChart();
    }
  }

  //Once we are sure the component has rendered, inject a javascript controller from TradingView into the control
  componentDidMount(){
    this.injectTradingViewJavascript();
  }

  //TradingViewChart renders as a div which will be populated by the RefreshChart() method
  //  tradingviewchartcontainer is the main container. 
  //  tradingview_274b1 is the div which contains the chart. we render an empty one to start
  //  tradingview-widget-copyright renders a copywrite (will dynamically update w/ ticker)
  render() {
    return (
      <div id="tradingviewchartcontainer" style={{visibility:(this.shouldShowChart()===true)?'visible':'hidden'}} className="tradingview-widget-container">
        <div id="tradingview_274b1"></div>
        <div className="tradingview-widget-copyright">
          <OutboundLink href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noreferrer noopener" target="_blank">
                <span className="blue-text">{this.props.ticker} Chart</span></OutboundLink> by TradingView
        </div>
      </div>
    );
  }

}



