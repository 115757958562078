import React from "react"
import SocialStats from "./socialstats"
import StockTwitsFeed from "./feed"
import ExplainerPopover from "../general/explainerpopover"

export default class SocialPanel extends React.Component {
    
    render() {
        return (
            <div className="card" >
            <div className="card-body">
                <h4 className="card-title" >Social Activity for {this.props.ticker} 
                    <ExplainerPopover maxWidth="500px">
                    Here we analyze recent social media activity related to {this.props.ticker} to glean public sentiment, by looking at the relative bullish- or bearish-ness of the posts, and how rapidly people are posting
                    </ExplainerPopover>
                </h4>
                
                <SocialStats ticker={this.props.ticker} />
                <StockTwitsFeed ticker={this.props.ticker} />
            </div>
            </div>
        )
    }
  
}